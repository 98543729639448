import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
{
    path:'/',
redirect:'/login',
component:resolve => require(['../views/login.vue'], resolve)
},



{
    path:'/index4',
    name:'home44 ',
    component:resolve => require(['../views/jszj/home4.vue'], resolve) ,
    children:[
        {
            path:'/index4/sy44',
            name:'sy1',  
            component:resolve => require(['../views/jszj/sy.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index4/gz',
            name:'gz',
            component:resolve => require(['../views/jszj/gz.vue'], resolve) ,
            meta: {
                title: '5',   
            }
          },
          {
            path:'/index4/pt',
            name:'pt',
            component:resolve => require(['../views/jszj/pt.vue'], resolve) ,
            meta: {
                title: '5',   
            }
          },
        
          {
            path:'/index4/sh44',
            name:'js2',
            component:resolve => require(['../views/jszj/sh.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },
          {
            path:'/index4/js44',
            name:'js2',
            component:resolve => require(['../views/jszj/js.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },

          {
            path:'/index4/xiangqing1',
            name:'xiangqings1', 
            component:resolve => require(['../views/jszj/xiangqing1.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
           
          {
            path:'/index4/xiangqing',
            name:'xiangqings', 
            component:resolve => require(['../views/jszj/xiangqing.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index4/xiaoxi44',
            name:'xiaoxi',   
            component:resolve => require(['../views/jszj/xiaoxi.vue'], resolve) ,  
            meta: {
                title: '1',   
            }
          },
          
          {
            path:'/index4/gonggao44',
            name:'gonggao2',   
            component:resolve => require(['../views/jszj/gonggao.vue'], resolve) ,  
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index4/cw44',
            name:'cw44',  
            component:resolve => require(['../views/jszj/cw.vue'], resolve) ,  
            meta: {
                title: '4',   
            }
          },
          {
            path:'/index4/dd44',
            name:'dd2',   
            component:resolve => require(['../views/jszj/dd.vue'], resolve) ,  
            meta: {
                title: '3',   
            }
          
          },
    ]
},




{
    path:'/index5',
    name:'home55 ',  
    component:resolve => require(['../views/jszy/home5.vue'], resolve) ,
    children:[
        {
            path:'/index5/sy55',
            name:'sy1',  
            component:resolve => require(['../views/jszy/sy.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
         
          {
            path:'/index5/xiaoxi55',
            name:'xiaoxi', 
            component:resolve => require(['../views/jszy/xiaoxi.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
           
          {
            path:'/index5/xiangqing',
            name:'xiangqing123', 
            component:resolve => require(['../views/jszy/xiangqing.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index5/xiangqing2',
            name:'xiangqing2222', 
            component:resolve => require(['../views/jszy/xiangqing2.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          
          {
            path:'/index5/gonggao55',
            name:'gonggao2',  
            component:resolve => require(['../views/jszy/gonggao.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
        
          {
            path:'/index5/dd55',
            name:'dd2', 
            component:resolve => require(['../views/jszy/dd.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          
          },
    ]
},





{
    path:'/index6',
    name:'home66 ',
    component:resolve => require(['../views/cw/home6.vue'], resolve) ,
    children:[
        {
            path:'/index6/sy66',
            name:'sy1',  
            component:resolve => require(['../views/cw/sy.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
         
          {
            path:'/index6/xiaoxi66',
            name:'xx66',  
            component:resolve => require(['../views/cw/xiaoxi.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index6/xiangqing',
            name:'xiangqing',  
            component:resolve => require(['../views/cw/xiangqing.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          }, {
            path:'/index6/xiangqing2',
            name:'xiangqing2',  
            component:resolve => require(['../views/cw/xiangqing2.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index6/gonggao66',
            name:'gonggao6', 
            component:resolve => require(['../views/cw/gonggao.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
            
        
          {
            path:'/index6/cw66',
            name:'cw66', 
            component:resolve => require(['../views/cw/cw.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },
          {
            path:'/index6/cwmx66',
            name:'cwmx66',  
            component:resolve => require(['../views/cw/cwmx.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },
          {
            path:'/index6/fc66',
            name:'fc66', 
            component:resolve => require(['../views/cw/fc.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },
          {
            path:'/index6/fp66',
            name:'fp66', 
            component:resolve => require(['../views/cw/fp.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },
          {
            path:'/index6/js',
            name:'fp66', 
            component:resolve => require(['../views/cw/js.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },
         
    ]
},



{
    path:'/index9',
    name:'home77 ',
    component:resolve => require(['../views/cwzy/home.vue'], resolve) ,
    children:[
        {
            path:'/index9/sy',
            name:'sy1',  
            component:resolve => require(['../views/cwzy/sy.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
         
          {
            path:'/index9/xiaoxi',
            name:'xx66',  
            component:resolve => require(['../views/cwzy/xiaoxi.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          
       
            
        
          {
            path:'/index9/cw',
            name:'cw66', 
            component:resolve => require(['../views/cwzy/cw.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },
        
       
      
      
         
    ]
},




{
    path:'/index3',
    name:'Home3',
    component:resolve => require(['../views/ywjl/home3.vue'], resolve) ,

    children:[
        {
            path:'/index3/sy1',
            name:'sy1',
           
            component:resolve => require(['../views/ywjl/sy1.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index3/xiangqing',
            name:'xiangqing',
           
            component:resolve => require(['../views/ywjl/xiangqing.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index3/xiangqing2',
            name:'xiangqing2',
           
            component:resolve => require(['../views/ywjl/xiangqing2.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index3/js2',
            name:'js2', 
            component:resolve => require(['../views/ywjl/js2.vue'], resolve) ,
            meta: {
                title: '3',   
            }
          },
          {
            path:'/index3/xiaoxi',
            name:'xiaoxi', 
            component:resolve => require(['../views/ywjl/xiaoxi.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          
          {
            path:'/index3/gonggao2',
            name:'gonggao2', 
            component:resolve => require(['../views/ywjl/gonggao1.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index3/dd2',
            name:'dd2', 
            component:resolve => require(['../views/ywjl/dd2.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          
          },
          {
            path:'/index3/hy2',
            name:'hy2', 
            component:resolve => require(['../views/ywjl/hy2.vue'], resolve) ,
            meta: {
                title: '4',   
            }
          
          },
    ]
},{
    path:'/index2',
    name:'Home2', 
    component:resolve => require(['../views/sczj/home2.vue'], resolve) ,
    children:[
        {
            path:'/index2/sy1',
            name:'sy1', 
            component:resolve => require(['../views/sczj/sy1.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index2/xiangqing2',
            name:'xiangqing2', 
            component:resolve => require(['../views/sczj/xiangqing2.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index2/xiangqing',
            name:'xiangqing', 
            component:resolve => require(['../views/sczj/xiangqing.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index2/hytj',
            name:'hytj', 
            component:resolve => require(['../views/sczj/hytj.vue'], resolve) ,
            meta: {
                title: '5',   
            }
          },
          {
            path:'/index2/xiaoxi',
            name:'xiaoxi',  
            component:resolve => require(['../views/sczj/xiaoxi.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index2/js2',
            name:'js2', 
            component:resolve => require(['../views/sczj/js2.vue'], resolve) ,
            meta: {
                title: '2',   
            }
          },
          {
            path:'/index2/gonggao2',
            name:'gonggao2', 
            component:resolve => require(['../views/sczj/gonggao1.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index2/dd2',
            name:'dd2', 
            component:resolve => require(['../views/sczj/dd2.vue'], resolve) ,
            meta: {
                title: '3',   
            }
          },
          {
            path:'/index2/cw2',
            name:'cw2', 
            component:resolve => require(['../views/sczj/cw2.vue'], resolve) ,
            meta: {
                title: '4',   
            }
          },
          {
            path:'/index2/hy2',
            name:'hy2', 
            component:resolve => require(['../views/sczj/hy2.vue'], resolve) ,
            meta: {
                title: '5',   
            }
          },
    ]
},







// {
//     path:'/index2',
//     name:'Home2',
//     component:Home2,
//     children:[
//         {
//             path:'/index2/sy1',
//             name:'sy1',
//             component:SY1,
//             meta: {
//                 title: '1',   
//             }
//           },
//           {
//             path:'/index2/js2',
//             name:'js2',
//             component:js2,
//             meta: {
//                 title: '2',   
//             }
//           },
//           {
//             path:'/index2/gonggao2',
//             name:'gonggao2',
//             component:gonggao2,
//             meta: {
//                 title: '1',   
//             }
//           },
//           {
//             path:'/index2/dd2',
//             name:'dd2',
//             component:dd2,
//             meta: {
//                 title: '3',   
//             }
//           },
//           {
//             path:'/index2/cw2',
//             name:'cw2',
//             component:cw2,
//             meta: {
//                 title: '4',   
//             }
//           },
//           {
//             path:'/index2/hy2',
//             name:'hy2',
//             component:hy2,
//             meta: {
//                 title: '5',   
//             }
//           },
//     ]
// },
  {
    path:'/index',
    name:'Home', 
    component:resolve => require(['../views/home.vue'], resolve) ,
    children:[
        {
            path:'/index/sy',
            name:'sy', 
            component:resolve => require(['../views/sy.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index/js',
            name:'Js',  
            component:resolve => require(['../views/js.vue'], resolve) ,
            meta: {
                title: '2',   
            }},

            {
                path:'/index/cwzj',
                name:'cwzj', 
                component:resolve => require(['../views/cwzj.vue'], resolve) ,
                meta: {
                    title: '2',   
                }},
            {
                path:'/index/zhiwei',
                name:'zhiwei', 
                component:resolve => require(['../views/zhiwei.vue'], resolve) ,
                meta: {
                    title: '2',   
                }},
            {
                path:'/index/xiangqing',
                name:'Xq', 
                component:resolve => require(['../views/xiangqing.vue'], resolve) ,
                meta: {
                    title: '1',   
                }},

                {
                    path:'/index/xiangqing2',
                    name:'Xq1', 
                    component:resolve => require(['../views/xiangqing2.vue'], resolve) ,
                    meta: {
                        title: '1',   
                    }},
            {
                path:'/index/dd',
                name:'dd',
                component:resolve => require(['../views/dd.vue'], resolve) ,
                meta: {
                    title: '3',   
                }},
                {
                    path:'/index/tsdd',
                    name:'tsdd', 
                    component:resolve => require(['../views/tsdd.vue'], resolve) ,
                    meta: {
                        title: '3',   
                    }},
                {
                    path:'/index/cwmx',
                    name:'cwmx', 
                    component:resolve => require(['../views/cwmx.vue'], resolve) ,
                    meta: {
                        title: '4',   
                    }},
                    {
                        path:'/index/fp',
                        name:'fp', 
                        component:resolve => require(['../views/fp.vue'], resolve) ,
                        meta: {
                            title: '4',   
                        }},
                    {
                        path:'/index/fc',
                        name:'fc', 
                        component:resolve => require(['../views/fc.vue'], resolve) ,
                        meta: {
                            title: '4',   
                        }},
                {
                    path:'/index/cw',
                    name:'cw',
                    component:resolve => require(['../views/cw.vue'], resolve) ,
                    meta: {
                        title: '4',   
                    }},
                    {
                        path:'/index/pt',
                        name:'pt',
                        component:resolve => require(['../views/pt.vue'], resolve) ,
                        meta: {
                            title: '5',   
                        }},
                        {
                            path:'/index/menu',
                            name:'menu',
                            component:resolve => require(['../views/menu.vue'], resolve) ,
                            meta: {
                                title: '5',   
                            }},
                        {
                            path:'/index/ry',
                            name:'ry',
                            component:resolve => require(['../views/ry.vue'], resolve) ,
                            meta: {
                                title: '5',   
                            }},
                            {
                                path:'/index/pj',
                                name:'pj',
                                component:resolve => require(['../views/pj.vue'], resolve) ,
                                meta: {
                                    title: '5',   
                                }},
                        {
                            path:'/index/hy',
                            name:'hy',
                            component:resolve => require(['../views/hy.vue'], resolve) ,
                            meta: {
                                title: '6',   
                            }},
                            {
                                path:'/index/xiaoxi',
                                name:'xx', 
                                component:resolve => require(['../views/xiaoxi.vue'], resolve) ,
                                meta: {
                                    title: '1',   
                                }},
                                {
                                    path:'/index/gonggao',
                                    name:'gg',
                                    component:resolve => require(['../views/gongao.vue'], resolve) ,
                                    meta: {
                                        title: '1',   
                                    }},

                                    {
                                        path:'/index/hytj',
                                        name:'hytj',
                                        component:resolve => require(['../views/hytj.vue'], resolve) ,
                                        meta: {
                                            title: '6',   
                                        }}
          
    ]
  },
 

  {
    path:'/index7',
    name:'Home', 
    component:resolve => require(['../views/dls/home.vue'], resolve) ,
    children:[
        {
            path:'/index7/sy',
            name:'sy', 
            component:resolve => require(['../views/dls/sy.vue'], resolve) ,
            meta: {
                title: '1',   
            }
          },
          {
            path:'/index7/js',
            name:'Js',  
            component:resolve => require(['../views/dls/js.vue'], resolve) ,
            meta: {
                title: '2',   
            }},

           
            {
                path:'/index7/zhiwei',
                name:'zhiwei', 
                component:resolve => require(['../views/dls/zhiwei.vue'], resolve) ,
                meta: {
                    title: '2',   
                }},
            {
                path:'/index7/xiangqing',
                name:'Xq', 
                component:resolve => require(['../views/dls/xiangqing.vue'], resolve) ,
                meta: {
                    title: '1',   
                }},
                {
                    path:'/index7/xiangqing2',
                    name:'Xq2', 
                    component:resolve => require(['../views/dls/xiangqing2.vue'], resolve) ,
                    meta: {
                        title: '1',   
                    }},
            {
                path:'/index7/dd',
                name:'dd',
                component:resolve => require(['../views/dls/dd.vue'], resolve) ,
                meta: {
                    title: '3',   
                }},
                {
                    path:'/index7/tsdd',
                    name:'tsdd', 
                    component:resolve => require(['../views/dls/tsdd.vue'], resolve) ,
                    meta: {
                        title: '3',   
                    }},
                {
                    path:'/index7/cwmx',
                    name:'cwmx', 
                    component:resolve => require(['../views/dls/cwmx.vue'], resolve) ,
                    meta: {
                        title: '4',   
                    }},
                    {
                        path:'/index7/fp',
                        name:'fp', 
                        component:resolve => require(['../views/dls/fp.vue'], resolve) ,
                        meta: {
                            title: '4',   
                        }},
                    {
                        path:'/index7/fc',
                        name:'fc', 
                        component:resolve => require(['../views/dls/fc.vue'], resolve) ,
                        meta: {
                            title: '4',   
                        }},
                {
                    path:'/index7/cw',
                    name:'cw',
                    component:resolve => require(['../views/dls/cw.vue'], resolve) ,
                    meta: {
                        title: '4',   
                    }},
                    {
                        path:'/index7/pt',
                        name:'pt',
                        component:resolve => require(['../views/dls/pt.vue'], resolve) ,
                        meta: {
                            title: '5',   
                        }},
                        {
                            path:'/index7/pj',
                            name:'pj',
                            component:resolve => require(['../views/dls/pj.vue'], resolve) ,
                            meta: {
                                title: '5',   
                            }},
                        {
                            path:'/index7/hy',
                            name:'hy',
                            component:resolve => require(['../views/dls/hy.vue'], resolve) ,
                            meta: {
                                title: '6',   
                            }},
                            {
                                path:'/index7/xiaoxi',
                                name:'xx', 
                                component:resolve => require(['../views/dls/xiaoxi.vue'], resolve) ,
                                meta: {
                                    title: '1',   
                                }},
                                {
                                    path:'/index7/gonggao',
                                    name:'gg',
                                    component:resolve => require(['../views/dls/gonggao.vue'], resolve) ,
                                    meta: {
                                        title: '1',   
                                    }},

                                    {
                                        path:'/index7/hytj',
                                        name:'hytj',
                                        component:resolve => require(['../views/dls/hytj.vue'], resolve) ,
                                        meta: {
                                            title: '6',   
                                        }}
          
    ]
  },

    {
        path:'*',
        name:'404',
        component:resolve => require(['../views/404.vue'], resolve) ,},
        {
            path:'/login',
            name:'login', 
            component:resolve => require(['../views/login.vue'], resolve)}
 
]


const router = new VueRouter({
 
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
    const isLogin = localStorage.token ? true : false;
    if(to.path == '/login'){
        next();
    }else{
        isLogin ? next() : next('/login')
    }
})

// request.interceptors.request.use(
//     config => {
//       if (config.method == 'post') {
//         config.data = {
//           ...config.data,
//           _t: Date.parse(new Date()) / 1000
//         }
//       } else if (config.method == 'get') {
//         config.params = {
//           _t: Date.parse(new Date()) / 1000,
//           ...config.params
//         }
//       }
//       return config
//     }, function (error) {
//       return Promise.reject(error)
//     }
//   )

export default router
