import axios from 'axios'
import { Loading ,Message} from  'element-ui'


let loading;
function startLoading(){
     loading = Loading.service({
         lock:true,
         text:'拼命加载中...',
         background:'rgba(0,0,0,.7)'
     })
}
function endLoading(){
    loading.close()
}


//请求拦截
axios.interceptors.request.use(config=>{
    startLoading();
    return config;
},error=>{
    return Promise.reject(error)
})


//相应拦截
axios.interceptors.response.use(response=>{
    if(response.data.code ==-3){
        localStorage.clear();
    }
    endLoading();
    return response;
},error=>{

    endLoading();

})

export default axios