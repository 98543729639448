import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './http.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import 'default-passive-events'
Vue.use(Viewer);
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});
Vue.config.productionTip = false
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
